<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <a-btn-refresh @click="$refs.table.updateData()" />
      <a-btn-add :icon="'far fa-plus-square'" :title="'Создать документ'" v-if="getAccess('documents.cashRemainder.create')" @click="createNew()" />
    </template>
    <portal to="v-main">
      <s-document-head
        v-model="showEditDialog"
        v-if="showEditDialog"
        :api="url"
        :m="m"
        :config="configHead"
        :title="m.title"
        :id="idItemShow"
        opt="delete"
        @onDelete=""
      />
    </portal>

    <a-table-f-api
      ref="table"
      :api="url"
      :model="getModelList(m)"
      :useQuery="false"
      :defaults="defaults"
      :selectedRows="selectedRows"
      @click="
        counter += 1;
        onDetectClick($event);
      "
    >
      <template v-slot:footer>
        <div class="f-table-footer">
          <div class="f-table-row">
            <div style="margin: 6px 6px" v-if="$refs.table">Показано: {{ $refs.table.pager.count }},</div>
          </div>
        </div>
      </template>
    </a-table-f-api>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, genModel, keyDetect, doubleClickDetect } from "@/components/mixings";
import libsCash from "./libsCash";

export default {
  mixins: [getAccess, genModel, libsCash, keyDetect, doubleClickDetect],
  components: {
    CashDialog: () => import("./dialogs/cashDialog"),
    selectDialog: () => import("./dialogs/cashOperationSelectDialog"),
  },
  data() {
    return {
      idItemShow: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.cashRemainder,
      operation: null,
      url: "/accounting/doc/cash_balance",
      defaults: {
        sort: { key: "id", order: "DESC" },
        paramName: "documentCashRemainder",
      },
      contextData: null,
    };
  },
  created() {
    this.$root.title = this.m.title;
  },
  computed: {
    configHead() {
      return this.m.config.default || {};
    },
    permit() {
      return this.getAccess("menu.documentCashIn");
    },
    contextMenuItems() {
      let res = [];
      return res;
    },
  },
  watch: {
    showEditDialog(v) {
      if (!v) {
        this.idItemShow = 0;
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onDoubleClick(d) {
      this.onClickRow(d);
    },
    onSingleClick() {},
    onClickRow(d) {
      this.idItemShow = d.row.id;
      this.showEditDialog = true;
      return;
      this.$router.push({ name: "ordersGoods_view", params: { id: d.row.id } });
    },
    createNew() {
      this.showEditDialog = true;
    },
  },
};
</script>
